import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import * as styles from "./PopupWrapper-tw-styles"
import useScreenSize from "../../../hooks/useScreenSize"

const PopupWrapper = props => {
  const { children, variant, closeOnBodyclick } = props
  const style = styles[variant] || styles.defaultStyle

  const [showModal, setShowModal] = useState(false)
  const [modalDoc, setModalDoc] = useState(null)

  const closeModal = () => {
    document.dispatchEvent(new Event("close_newsletter_modal"))
    setShowModal(false)
  }
  const screen = useScreenSize()

  useEffect(() => {
    let modalTimeout

    if (
      (location?.href?.replace(location.origin, "") === "/" || location?.href?.replace(location.origin, "") === "/es/") &&
      !location.href.includes("newsletter")
    ) {
      modalTimeout = setTimeout(() => {
        setShowModal(true)
      }, 1)
    }

    return () => {
      if (modalTimeout) {
        clearTimeout(modalTimeout)
      }
    }
  }, [])

  const handleCloseOnKeyPress = event => {
    if (event.key === "Escape" || event.key === "Esc") {
      close()
    }
  }
  const onClick = () => {
    if (closeOnBodyclick) {
      close()
    }
  }

  const close = () => {
    closeModal()
  }

  useEffect(() => {
    setModalDoc(document.getElementById("base-modal"))
    document.addEventListener("keydown", handleCloseOnKeyPress)
    return () => {
      document.removeEventListener("keydown", handleCloseOnKeyPress)
    }
  }, [])

  const modal = showModal ? (
    <div className={style?.wrapper} onClick={onClick}>
      <div className={style?.gap}>
        <div className={style?.container}>
          <div className={style?.content}>
            <a
              href="https://www.promo.royalcontigo.com/promocion/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  screen?.isMobile
                    ? "https://images.ctfassets.net/awi7573i7srw/3PlCBQsodadi9wBas3rabW/a0f9ddccee810e10dec72d2d52db4bb8/ROYAL_Banner_Mobile_Navidad_390x620px.jpg"
                    : "https://images.ctfassets.net/awi7573i7srw/4tJDgahLSpn1ecmnhDtdJg/33a58cbad65a9adf791f0fd23dc70fa1/ROYAL_Banner_Desktop_Navidad_1920x780px__1_.jpg"
                }
              />
            </a>
            <button
              className="modal-close"
              onClick={close}
              aria-label="Close"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null

  return modalDoc ? ReactDOM.createPortal(modal, modalDoc) : null
}

PopupWrapper.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.any,
  variant: PropTypes.string,
  closeOnBodyclick: PropTypes.bool,
}

export default PopupWrapper
