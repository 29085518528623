// export const defaultStyle = {
//   modalwrpr: "bg-white flex justify-center items-center w-full h-full fixed top-0 left-0 z-10 ",
//   closeBtn: "absolute right-16 top-9 w-[64px] h-[64px]",
//   modalContainer: 'modalContainer popupContainer w-full h-[0rem] flex absolute top-[10%]',
// }
export const defaultStyle = {
  modalwrpr:
    "bg-white flex justify-center items-center w-full h-full fixed top-0 left-0 right-0 z-20 bg-opacity-75",
  closeBtn:
    "absolute right-16 top-[104px] w-[48px] h-[48px] md:w-[64px] md:h-[64px] closeBtn",
  modalContainer: "w-full",
}
export const POPUP_MODAL = {
  ...defaultStyle,
  gap: "flex justify-center items-center",
  container:
    "absolute lg:max-w-[900px] w-[90%] top-[50%] -translate-x-[50%] left-[50%] -translate-y-[50%]",
  content:
    "rounded-[30px] border-white border-[3px] border-solid bg-white overflow-hidden flex justify-center",
  closeButton: "modal-close",
  wrapper: "fixed z-[999] fixed left-0 right-0 top-0 bottom-0 bg-slate-900/70",
}
