export const defaultStyle = {
    header: "fixed z-[100] left-0 right-0 bg-red",
    wrapper: "section-wrapper flex flex-wrap justify-between items-center py-4",
    logoWrapper: "flex items-center justify-center ml-auto mr-auto md:ml-[unset] md:mr-[unset]",
    logoImage: "w-[107px] md:w-[142px]",
    navWrapper: "hidden lg:block lg:ml-auto lg:mr-24",
    navList: "flex flex-row gap-8",
    navListA: "navigation-menu text-white navigation-shadow",
    mobileNavWrapper:
        "absolute top-[66px] left-0 w-full h-[calc(100vh-66px)] px-[24px] pt-[24px] pb-[80px] bg-red transition-all duration-300 ease-in-out",
    mobileNav: "overflow-y-auto h-full w-full flex justify-center ",
    mobileNavContent: "flex flex-col justify-between w-full gap-10",
    mobileNavList: "flex flex-col gap-8 items-center",
    mobileNavListA: "navigation-menu text-white text-center navigation-shadow",
}
